'use strict';

import React, { useEffect, useRef, useState, lazy } from 'react';
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { useMst, makeSafeStore } from "Models/root.js";

export let prefersReducedMotion = null

// Wrap in this check to prevent test failing.
// @todo: This should be a hook, like useLargeContainer below.
if (window && window.matchMedia) {
    const checkReducedMotion = mediaQuery => {
        prefersReducedMotion = mediaQuery.matches
    }

    const mediaQuery = self.matchMedia('(prefers-reduced-motion: reduce)');

    checkReducedMotion(mediaQuery)
    mediaQuery.addEventListener('change', () => {
        checkReducedMotion(mediaQuery)
    });
};

export const useLargeContainer = (containerRef, threshold)=> {
    const [isLargeContainer, setIsLargeContainer] = useState(false);

    const checkSize = () => {
        if (!containerRef.current) {
          return;
        };
        const containerWidth = containerRef.current.offsetWidth;
        setIsLargeContainer(containerWidth >= threshold);
    };

    useEffect(() => {
        checkSize(); // Check size on mount.

        window.addEventListener('resize', checkSize);

        return () => {
            // Clean up the event listener on unmount.
            window.removeEventListener('resize', checkSize);
        };
    }, [containerRef, threshold]);

    return isLargeContainer;
};

export const sortWeekdays = weekdays => {
    const order = {
        "Monday": 1,
        "Tuesday": 2,
        "Wednesday": 3,
        "Thursday": 4,
        "Friday": 5,
        "Saturday": 6,
        "Sunday": 7
    }

    return weekdays.sort((a, b)=> {
        return order[a] - order[b];
    });
};

export const periodsInMs = {
    day: 86400000,
    hour: 3600000
};

export const prettyWeekdays = weekdays=> {
    // Wrap every array item in a fw-bold span.
    weekdays = weekdays.map((w, i)=> <span key={w} className="fw-bold">{w}</span>)
    return oxfordJoin(weekdays);
};

export const prettyDate = (timestamp, unit) => {
    if (unit!=='ms') {
        timestamp = timestamp * 1000
    };

    const date = new Date(timestamp);

    return date.toLocaleDateString(undefined, { dateStyle: 'long' });
};

export const prettyDateNextDay = (timestamp, unit) => {
    if (unit!=='ms') {
        timestamp = timestamp * 1000
    };

    const date = new Date(timestamp);
    date.setDate(date.getDate()+1);

    return date.toLocaleDateString(undefined, { dateStyle: 'long' });
};

export const continents = [
    { name: 'Africa', code: 'af' },
    { name: 'Antarctica', code: 'an' },
    { name: 'Asia', code: 'as' },
    { name: 'Europe', code: 'eu' },
    { name: 'North America', code: 'na' },
    { name: 'Oceania', code: 'oc' },
    { name: 'South America', code: 'sa' },
    { name: 'Unknown', code: 'xx' },
];

export const getDistinctPropsByName = (data, name)=> [...new Set((data || []).map(item=> item[name]))];

export const useTimeout = (callback, delay, ...watchers) => {
    const internalcb = useRef();

    useEffect(() => {
        internalcb.current = callback;
    });

    useEffect(() => {
        const timeout = delay
            ? setTimeout(() => internalcb.current(), delay)
            : null;

        return () => clearTimeout(timeout);
    }, [callback, delay, ...watchers]);
};

export const useInterval = (callback, delay, ...watchers) => {
    const internalcb = useRef();

    useEffect(() => {
        internalcb.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delay == null) {
            return;
        }

        const interval = setInterval(() => internalcb.current(interval), delay);

        return () => clearInterval(interval);
    }, [delay, ...watchers]);
}

export const useTitle = (title) => {
    useEffect(() => {
        if (title == null) {
            return;
        }

        const prev = document.title;

        document.title = `${title} - TestLocally`;

        return () => {
            document.title = prev;
        }
    }, [title]);
};

export const oxfordJoin = array => {
    if (array.length < 2) {
        return array;
    }

    if (array.length === 2) {
        return [array[0], ' and ', array[1]];
    }

    return array.reduce((final, item, i) => {
        const last = i === (array.length - 1);

        return last
            ? [...final, 'and ', item]
            : [...final, <span key={`nowrap-${i}`} className="text-nowrap">{item},</span>, ' '];
    }, [])
};

export const addCountryPrefix = countryCode => {
    return ['ae', 'gb', 'us'].includes(countryCode) ? { prefix: 'the '} : {};
}

export const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

export const templateConfig = name => {
    const config = JSON.parse(document?.getElementById('template-config')?.textContent || '{}');

    return name != null ? config[name] : config;
}

export const useAuthedRoute = isAuthed => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const { user, session } = useMst()

    useEffect(() => {
        user.load().then(() => setLoading(false));
    }, [location.pathname]);

    useInterval(() => {
        session.check().catch(() => user.load())
    }, 30000)

    return {
        loading,
        redirect: <Navigate to="/login" state={{ from: location }} />,
        isAuthed: isAuthed(user),
    }
};

export const updateArrayItem = (array, item, updates) => {
    const idx = array.indexOf(item);

    return idx < 0
        ? array
        : [
            ...array.slice(0, idx),
            {...item, ...updates},
            ...array.slice(idx + 1),
        ];
}

export const removeArrayItem = (array, item) => {
    const idx = array.indexOf(item);

    return idx < 0
        ? array
        : [
            ...array.slice(0, idx),
            ...array.slice(idx + 1),
        ];
}

export const randomItems = (array, length, isDuplicate) => {
    if (array.length <= length) {
        return [...array];
    }

    const random = [];

    while (random.length < length) {
        const choice = array[Math.floor(Math.random() * array.length)];

        if (isDuplicate && random.some(item => isDuplicate(choice, item))) {
            continue;
        }

        random.push(choice);
    }

    return random;
};

export const plural = (count, singular = '', plural = 's') => count === 1 ? singular : plural;

export const errmsg = (err, prefix) => {
    const message = err?.response?.data?.message;

    return !!prefix
        ? `${prefix}${message ? `: ${message}` : ''}`
        : message;
}

export const adminTime = timestamp => {
    const d = new Date(timestamp * 1000);

    const yyyy = d.getUTCFullYear(),
        mm = String(d.getUTCMonth() + 1).padStart(2, '0'),
        dd = String(d.getUTCDate()).padStart(2, '0'),
        h = String(d.getUTCHours()).padStart(2, '0'),
        m = String(d.getUTCMinutes()).padStart(2, '0'),
        s = String(d.getUTCSeconds()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd} ${h}:${m}:${s}`;
}

export const adminDate = timestamp => {
    const d = new Date(timestamp * 1000);

    const yyyy = d.getUTCFullYear(),
        mm = String(d.getUTCMonth() + 1).padStart(2, '0'),
        dd = String(d.getUTCDate()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`;
}

export const centsToUsd = cents => new Intl.NumberFormat(
    navigator?.language || 'en-ca', // use the browser locale for pete's sake
    { style: 'currency', currency: 'USD'}
).format(cents / 100);

// why are url params in an object and query params in some kind of weird iterator
export const useQueryParams = () => {
    const [ query, setQuery ] = useSearchParams();

    return { setQuery, ...Object.fromEntries(query.entries()) };
};

export const urlify = target => target.match(/^https?:\/\//) ? target : `https://${target}`;

// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0#3314
export const lazyWithRetry = (componentImport) =>
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(
                'page-has-been-force-refreshed'
            ) || 'false'
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem(
                'page-has-been-force-refreshed',
                'false'
            );

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem(
                    'page-has-been-force-refreshed',
                    'true'
                );
                return window.location.reload();
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });

export const FEATURES = {
    'change-browser-engine': {
        title: 'More browsers',
        description: 'Test with Google Chrome, Apple Safari, Mozilla Firefox, or Microsoft Edge',
    },
    'change-viewport-size': {
        title: 'More screen sizes',
        description: 'Test at tablet or mobile phone sizes',
    },
    'compare-screenshots': {
        title: 'Compare screenshots',
        description: 'Interactively compare screenshots from different locations, side-by-side',
    },
    'export-test-to-pdf': {
        title: 'PDF export',
        description: 'Download test results as a PDF',
    },
    'location-sets': {
        title: 'Location sets',
        description: 'Save and manage named sets of locations',
    }
};

export const STRIPE_DASHBOARD = templateConfig('stripe')?.dashboard;
