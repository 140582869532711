'use strict';

import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import { NotFound } from "Pages/not-found.jsx";
import { useTitle } from "Root/helpers.jsx";
import MarkdownPage from "Components/markdown-page.jsx";

export const seoLanders = {
    '/automatic-screenshots': {
        title: 'Automatic Screenshots',
        importPath: 'landers/automatic-screenshots',
        hero: 'seo-landers/automatic-screenshots',
    },
    '/screenshot-service': {
        title: 'Screenshot Service',
        importPath: 'landers/screenshot-service',
        hero: 'seo-landers/screenshot-service',
    },
    '/view-website-from-another-country': {
        title: 'View Website From Another Country',
        importPath: 'landers/view-website-from-another-country',
        hero: 'seo-landers/view-website-from-another-country',
    }
};

const SeoLanderPage = ({ title, importPath, hero: heroPath }) => {
    const [hero, setHero] = useState();

    useTitle(title);

    useEffect(() => {
        import(`Images/${heroPath}.svg`).then(module => {
            // the default import for SVGs comes in as a functional component,
            // so i think we have to actually call it before we display the image
            // inline. this fixes some client-some warnings about 'typeof' being
            // an invalid svg attribute (and cleans up the attributes on the
            // resulting element, generally).
            setHero(module.default());
        });
    }, [heroPath]);

    return <>
        <div className="container col-xl-6 py-2">
            <div className="row flex-lg-row align-items-center g-5 py-5">
                {hero}
            </div>
        </div>
        <div className="container col-xl-6 py-2">
            <MarkdownPage sourcePath={importPath} />
        </div>
    </>;
}

export const SeoLander = () => {
    const { pathname } = useLocation();

    return seoLanders[pathname] != null
        ? <SeoLanderPage {...seoLanders[pathname]} />
        : <NotFound />;
};
